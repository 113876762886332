import linkStyles from 'components/primitives/links/Link.module.scss';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useSanaTexts } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { Link } from 'components/primitives/links';

const LoginPrompt = () => {
  const registrationAllowed = useSelector(state => state.settings.profile?.registrationAllowed);
  const [
    loginFirstTitle,
    loginFirstDescription,
    loginLink,
    registerLink,
  ] = useSanaTexts([
    'WishList_LoginFirstPopupTitle',
    'WishList_LoginFirstPopupDescription',
    'GoToLoginPage',
    registrationAllowed && 'WishList_LoginFirstPopupGoToRegisterPageLink',
  ]).texts;

  const linkClasses = `${linkStyles.link} ${linkStyles.arrowed}`;

  return (
    <>
      <h2>{loginFirstTitle}</h2>
      {loginFirstDescription}
      <div>
        <Link to={routesBuilder.forLogin} className={linkClasses}>
          {loginLink}
        </Link>
      </div>
      {registrationAllowed && (
        <div>
          <Link to={routesBuilder.forRegistration} className={linkClasses}>
            {registerLink}
          </Link>
        </div>
      )}
    </>
  );
};

export default memo(LoginPrompt);
