exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ConfirmationModal_modal-container{margin:0 auto;max-width:520px;width:100%}.ConfirmationModal_modal-buttons-block{margin-top:1.5em;padding:1.5em 0}.ConfirmationModal_btn-confirm{margin:0 auto}", ""]);

// exports
exports.locals = {
	"modal-container": "ConfirmationModal_modal-container",
	"modalContainer": "ConfirmationModal_modal-container",
	"modal-buttons-block": "ConfirmationModal_modal-buttons-block",
	"modalButtonsBlock": "ConfirmationModal_modal-buttons-block",
	"btn-confirm": "ConfirmationModal_btn-confirm",
	"btnConfirm": "ConfirmationModal_btn-confirm"
};