import styles from './OrderBox.module.scss';
import stylesQtyTextBox from 'components/primitives/product/quantityBox/QuantityTextBox.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import ViewProductButton from './ViewProductButton';
import { generateKey } from 'utils/helpers';
import { addProducts } from 'behavior/basket';
import { connect } from 'react-redux';
import { abilitiesPropType } from 'behavior/user';
import { createQuantityModel } from 'components/primitives/product';
import { default as QuantityTextBox } from 'components/primitives/product/quantityBox/QuantityTextBox';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsVisualDesigner } from 'utils/hooks';
import { useCultureName } from 'utils/hooks';
import { getNumberDecimalsSeparator } from 'utils/format';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { useDispatch } from 'react-redux';
import { addProductToWishList } from 'behavior/wishList';
import { HeartIcon, CartSummaryIcon } from 'components/primitives/icons';
import { useAbilities } from 'components/objects/user';
import LoginPrompt from '../product/wishlist/LoginPrompt';
import SuccessMessage from '../product/wishlist/SuccessMessage';
import { useModal } from 'components/objects/modals';
import ConfirmationModal from 'components/objects/wishList/confirmationModal';
import { anyEqual, intersect } from 'utils/helpers';
import ListContext from './b2b/ListContext';
import { useContext } from 'react';

const OrderBox = ({
  product,
  basketModifiedDate,
  basketUpdatedById,
  addProducts,
  className = '',
  withoutBackTo,
  preselectedAgreementLineId,
}) => {
  const {
    id,
    isOrderable,
    hasVariants,
    productConfiguratorInfo,
    variants,
    variantComponentGroups,
  } = product;

  const uomId = product.uom && product.uom.id;
  const uom = product.uoms
    ? product.uoms.find(u => u.id === uomId)
    : product.uom;
  const quantity = createQuantityModel(uom).initial;
  const [componentId] = useState(generateKey);
  const [disabled, setDisabled] = useState(false);
  const isDesignerMode = useIsVisualDesigner();
  const [quantities, setQuantities] = useState(quantity);
 
  const addToBasket = useCallback(() => {
    if (disabled)
      return;

    if (quantities > 0) {
      setDisabled(true);
      addProducts([{
        productId: id,
        uomId,
        quantity: quantities,
        salesAgreementLineId: preselectedAgreementLineId,
      }], componentId);
    }
  }, [uomId, quantities]);

  const culture = useCultureName();
  const quantityModel = useMemo(() => {
    const uom = product.uoms && product.uoms.find(u => u.id === uomId);
    return createQuantityModel(uom, true);
  }, [uomId]);
  const handleQuantityChange = useCallback(quantityBox => {
    if (quantityBox && quantityBox.value) {
      setQuantities(quantityBox.value);
    }
    else
      setQuantities(0);
  }, [uomId]);
  const handleEnter = useCallback(e => {
    if (e.key === 'Enter')
      addToBasket();
  }, [addToBasket]);
  const separator = getNumberDecimalsSeparator(culture);

  const dispatch = useDispatch();
  const {
    abilities: [canUseWishlist],
  } = useAbilities([AbilityTo.UseWishlist, AbilityTo.OrderProducts]);
  const showLoginFirstPopup = canUseWishlist === AbilityState.Unauthorized;

  let variantId;
  if (variants && variants.length) {
    variantId = getFirstVariant(variantComponentGroups);
  }

  const { opened, toggle } = useModal();
  const addToWishList = () => {
    showLoginFirstPopup ? toggle() : dispatch(addProductToWishList(product.id, uomId, variantId));
  };

  useEffect(() => {
    if (basketUpdatedById === componentId)
      setDisabled(false);
  }, [basketModifiedDate, basketUpdatedById]);

  let content = null;
  const placeholder = <Placeholder className="placeholder" />;

  if (isOrderable == null) {
    content = placeholder;
  } else if (isOrderable === false) {
    content = (
      <span className="msg-not-available">
        <SimpleText textKey="Product_NotAvailable" placeholder={placeholder} />
      </span>
    );
  } else if (productConfiguratorInfo.isProductConfigurable) {
    content = (
      <ViewProductButton
        textKey="ConfigureProduct"
        className={`${btnStyles.btnSmall} btn-configure`}
        titleTextKey="ConfigureProduct_ListPage"
        product={product}
        placeholder={placeholder}
        withoutBackTo={withoutBackTo}
        disabled={isDesignerMode}
      />
    );
  } else if (hasVariants) {
    content = (
      <ViewProductButton
        textKey="SelectVariants"
        className={`${btnStyles.btnSmall} btn-action`}
        product={product}
        placeholder={placeholder}
        withoutBackTo={withoutBackTo}
        disabled={isDesignerMode}
      />
    );
  } else {
    content = (
      <>
          <QuantityTextBox
            id={id}
            value={quantities}
            quantity={quantityModel}
            onChange={handleQuantityChange}
            onKeyDown={handleEnter}
            separator={separator}
            className={stylesQtyTextBox.plpQuantity}
            allowResetOnStepDecrease
          />        

          <button className={`${btnStyles.btn} ${btnStyles.btnAction} ${btnStyles.btnSmall} ${btnStyles.plpAddToCartIcon} ${btnStyles.addToCartIcon} ${styles.addToCartBtn} btn-action`}
            onClick={isDesignerMode ? null : addToBasket}
            placeholder={placeholder}
            disabled={quantities === 0}
          >          
          <SimpleText textKey="AddToCart" />
          </button>

        {canUseWishlist && (
          <div className={styles.wishlistHeart}>
            <button className={`${linkStyles.link} ${styles.addToWishListBtn}`} onClick={addToWishList} type="button">
              <HeartIcon />
            </button>
            <ConfirmationModal opened={opened} hide={toggle}>
              {showLoginFirstPopup ? <LoginPrompt /> : <SuccessMessage />}
            </ConfirmationModal>
          </div>
        )}
      </>
    );
  }

  return (
    <div className={`${className} ${styles.orderBox}`} role="article">
      {content}
    </div>
  );
};

OrderBox.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isOrderable: PropTypes.bool,
    hasVariants: PropTypes.bool,
    uom: PropTypes.shape({
      id: PropTypes.string,
    }),
    uoms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
    productConfiguratorInfo: PropTypes.object,
  }),
  abilities: abilitiesPropType,
  basketModifiedDate: PropTypes.number,
  basketUpdatedById: PropTypes.string,
  addProducts: PropTypes.func.isRequired,
  className: PropTypes.string,
  withoutBackTo: PropTypes.bool,
  preselectedAgreementLineId: PropTypes.string,
};

export default connect(({ basket, page: { salesAgreement } }) => ({
  basketModifiedDate: basket.modifiedDate,
  basketUpdatedById: basket.updated.updaterId,
  preselectedAgreementLineId: salesAgreement?.preSelectedLine?.id,
}),
  { addProducts },
)(OrderBox);

function getFirstVariant(variantComponentGroups) {
  if (!variantComponentGroups || !variantComponentGroups.length)
    return null;

  let availableVariants;
  const filter = component => anyEqual(component.variants, availableVariants);

  for (const group of variantComponentGroups) {
    if (!availableVariants)
      availableVariants = group.components[0].variants;
    else {
      const availableComponent = group.components.find(filter);
      availableVariants = intersect(availableComponent.variants, availableVariants);
    }
  }

  return availableVariants?.[0];
}