exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OrderBox_add-to-wish-list-placeholder{display:inline-block;height:2em;vertical-align:middle;width:5.5em}.OrderBox_add-to-wish-list-btn{align-items:center;display:inline-flex;vertical-align:middle}.OrderBox_add-to-wish-list-btn svg{font-size:1.2em;margin-right:.5em}.OrderBox_add-to-wish-list-btn img{margin-right:.5em}.OrderBox_quantity-box-custom input{margin:0}.OrderBox_add-to-cart-btn{align-items:center;display:inline-flex;margin-right:7px;vertical-align:middle}.OrderBox_add-to-cart-btn svg{font-size:1.2em;margin-right:.5em}.OrderBox_add-to-cart-btn img{margin-right:.5em}.OrderBox_order-box{align-items:center;display:flex}.OrderBox_order-box .msg-not-available{color:var(--theme-stockAmount_OutOfStock_Color,#c00);font-weight:500;line-height:1.6em}.OrderBox_order-box-custom{display:flex;justify-content:center}.OrderBox_order-box-custom .OrderBox_wishlistHeart{margin-left:6px}.OrderBox_matrix-footer{text-align:right}.OrderBox_unavailable-matrix{text-align:left}.OrderBox_unavailable-matrix .OrderBox_unavailable-matrix-control{margin-top:1em;text-align:center}.OrderBox_unavailable-matrix .OrderBox_close-btn{font-size:var(--theme-button_Big_Size,22px);transform:translateZ(0)}.OrderBox_variants-edit-btn{border-left-color:var(--theme-borderColor,#e6e6e6);border-left-style:solid;border-left-width:1px;margin-left:1.3em;padding-left:1.3em}.OrderBox_loader{align-items:center;display:flex;flex-direction:column;padding:4em}.OrderBox_loader .OrderBox_spinner{margin-bottom:1em}.OrderBox_btn-placeholder,.OrderBox_text-placeholder{display:inline-block;vertical-align:middle}.OrderBox_hide{display:none}.OrderBox_variants-amount{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"add-to-wish-list-placeholder": "OrderBox_add-to-wish-list-placeholder",
	"addToWishListPlaceholder": "OrderBox_add-to-wish-list-placeholder",
	"add-to-wish-list-btn": "OrderBox_add-to-wish-list-btn",
	"addToWishListBtn": "OrderBox_add-to-wish-list-btn",
	"quantity-box-custom": "OrderBox_quantity-box-custom",
	"quantityBoxCustom": "OrderBox_quantity-box-custom",
	"add-to-cart-btn": "OrderBox_add-to-cart-btn",
	"addToCartBtn": "OrderBox_add-to-cart-btn",
	"order-box": "OrderBox_order-box",
	"orderBox": "OrderBox_order-box",
	"order-box-custom": "OrderBox_order-box-custom",
	"orderBoxCustom": "OrderBox_order-box-custom",
	"wishlistHeart": "OrderBox_wishlistHeart",
	"matrix-footer": "OrderBox_matrix-footer",
	"matrixFooter": "OrderBox_matrix-footer",
	"unavailable-matrix": "OrderBox_unavailable-matrix",
	"unavailableMatrix": "OrderBox_unavailable-matrix",
	"unavailable-matrix-control": "OrderBox_unavailable-matrix-control",
	"unavailableMatrixControl": "OrderBox_unavailable-matrix-control",
	"close-btn": "OrderBox_close-btn",
	"closeBtn": "OrderBox_close-btn",
	"variants-edit-btn": "OrderBox_variants-edit-btn",
	"variantsEditBtn": "OrderBox_variants-edit-btn",
	"loader": "OrderBox_loader",
	"spinner": "OrderBox_spinner",
	"btn-placeholder": "OrderBox_btn-placeholder",
	"btnPlaceholder": "OrderBox_btn-placeholder",
	"text-placeholder": "OrderBox_text-placeholder",
	"textPlaceholder": "OrderBox_text-placeholder",
	"hide": "OrderBox_hide",
	"variants-amount": "OrderBox_variants-amount",
	"variantsAmount": "OrderBox_variants-amount"
};